import React from 'react'


import logo from "../assets/img/slide/logo.jpg";
import logo__ from "../assets/img/slide/logo__.png";
import {  FiPhoneCall  } from "react-icons/fi";
import { MdOutlineMail } from "react-icons/md";
import { MdEventAvailable } from "react-icons/md";

function Footer() {
  return (
<div class="container">
<footer class="text-center text-lg-start bg-dark text-white mt-5">

  <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">

  

   
 
  </section>


  <section class="">
    <div class="container text-center text-md-start mt-5">

      <div class="row mt-3">

        <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">

          <h2 class=" fw-bold mb-4 ">
            <i class="fas fa-gem me-3 p-4"></i> myidea
          </h2>
          <img src={logo__} class=" img-fluid d-block w-100" width="150" height="150" />
             
        </div>
 
       

    
        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">

          <h6 class="text-uppercase fw-bold mb-4">
            Services
          </h6>
          <p>
            <a href="#" class="text-reset" style={{ textDecoration:'none' }}>Remote Support</a>
          </p>
          <p>
            <a href="/" class="text-reset" style={{ textDecoration:'none' }}>Home IT Support</a>
          </p>
          <p>
            <a href="#chcheck" class="text-reset" style={{ textDecoration:'none' }}>Computer Health Check</a>
          </p>
          <p>
            <a href="#vremoval" class="text-reset" style={{ textDecoration:'none' }}>Virus Removal</a>
          </p>
          <p>
            <a href="#hnetworking" class="text-reset" style={{ textDecoration:'none' }}>Network Performance Test</a>
          </p>
          <p>
            <a href="#mdsupport" class="text-reset" style={{ textDecoration:'none' }}>Mobile Device Support</a>
          </p>
        </div>


    
        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">

          <h6 class="text-uppercase fw-bold mb-4">Contact Us</h6>
              <p class="p-1.5 navbar-brand"><MdEventAvailable size='1.5rem' color= 'white'/> Mon-Sat: 8am - 6pm</p> 
       
              <p class="p-1.5 navbar-brand"><MdOutlineMail size='1.5rem' color= 'white'/> Support@myidealtd.co.uk</p> 
              <p class="p-1.5 navbar-brand"><FiPhoneCall size='1.0rem' color= 'white'/>   03302235529</p> 
         
      
        </div>

      </div>

    </div>
  </section>
    <div class="text-center p-3" >
      ©2024 Copyright:
      <a>myidea</a>
    </div>

  </footer>
  </div>
  )
}

export default Footer
