import React,{useState,useEffect} from 'react';
import axios from 'axios';
import {Link,useParams,useNavigate} from 'react-router-dom';
import {Form,Button} from 'react-bootstrap';
import Card from 'react-bootstrap/Card';



function ProductEditPage() {

  const [name, setName] = useState('')
  // Below line is for image
  const [file, setFile] = useState("")

  const [description, setDescription] = useState('')
  const [price, setPrice] = useState('0')

  const [data,setData] = useState([]);
  const {id} = useParams(); 
  const navigate = useNavigate();

  const history = useNavigate();

  //Note: pls use async and await otherwise data will not come
  const fetch_Sin_Pro = async ()=> {

    const res = await axios.get(`https://api.myidealtd.co.uk/${id}`);
    
    
    setData(res.data.Single_Product);
    
     setName(res.data.name);
     setFile(res.data.file);

     setDescription(res.data.description);
     setPrice(res.data.price);

    
     console.log(data.file)
    
    } 



  useEffect(()=>{
    fetch_Sin_Pro();
  },[])


  
  const UpdateDetails = async () => {


    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }


    axios
       .put(`https://api.myidealtd.co.uk/update/${id}`,{
         name: name,
         photo: file,

         description:description,
         price: price,

    
 
       })
       .then(navigate('/'))
 
       
  } 




  return (
    <div className="container">
      <h1> Update  </h1>
       
       <p>Welcome Back {name}</p>
     
     
       <Form className='mt-3'>
       <Form.Group className="mb-3" >
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" 
                          name='name'  
                          value = {name}
                          onChange={(e)=> setName(e.target.value)} 
                        //  onChange = {setdata}
                          placeholder="" />
          </Form.Group>




          <Form.Group className="mb-3" >
            <Form.Label>Description</Form.Label>
            <Form.Control type="text" 
                          name='description'  
                          value = {description}
                          onChange={(e)=> setDescription(e.target.value)} 
                        //  onChange = {setdata}
                          placeholder="" />
          </Form.Group>

          <Form.Group className="mb-3" >
            <Form.Label>Price</Form.Label>
            <Form.Control type="text" 
                          name='price'  
                          value = {price}
                          onChange={(e)=> setPrice(e.target.value)} 
                        //  onChange = {setdata}
                          placeholder="" />
          </Form.Group>





          <Form.Group className="mb-3" >
            <Form.Label>Select Your Image</Form.Label>
            <Form.Control type="file" 
                          name='photo' 
                          onChange={(e)=>setFile(e.target.files[0])}  
                          placeholder="" />
          </Form.Group>
          

          <Button variant="primary" type="submit" onClick={UpdateDetails}>
            Update
          </Button>  
      </Form>
    </div>
  )
}

export default ProductEditPage
