import React from 'react'

function CheckoutSuccess() {

 


  return (
    <div>
      <div class="container text-center">
      <h1 class="mt-5">Checkout Successful</h1>
      <h2>Thanks you for your payment.</h2>

      <p>For any queries please contact at support@myidealtd.co.uk.</p>
    </div>
    </div>
  )
}

export default CheckoutSuccess
