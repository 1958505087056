/*

Line no 65 -to - 67 

<div class="card">
  <img src={`https://api.myidealtd.co.uk/uploads/${item.image}`} class="img-fluid d-block w-100"/>
</div>

For Production use -->  api.myidealtd.co.uk
For Testing use    -->  127.0.0.1
*/




import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

import React, { useEffect, useState,createContext,useContext } from 'react'

import {Button,Row,Col} from 'react-bootstrap';

import {Link,useParams,useNavigate} from 'react-router-dom';
import chcheck from "../assets/img/slide/chcheck.jpg";
import hnetworking from "../assets/img/slide/hnetworking.jpg";
import mdsupport from "../assets/img/slide/mdsupport.jpg";
import vremoval from "../assets/img/slide/vremoval.jpg";

import { TiTick } from "react-icons/ti";
import NavbarMain from '../components/NavbarMain.js'

import Footer from '../components/Footer.js'
import moment from "moment"

import { IoIosCheckboxOutline } from "react-icons/io";




function Pricing({addtocart,item,fetchProductList,data}) {
    const [quantity, setQuantity] = useState("1") 
    const navigate = useNavigate();
    const {id} = useParams(); 





    useEffect(()=>{
      fetchProductList();
    },[])
  
  return (
    <div className="container">
      <h1 class="text-center mt-5">Plans & Pricing </h1>
      <div className='row d-flex justify-content-between align-iteams-center mt-5'>
          {
    data.length > 0 ? data.map((item) => {
     
      const lines = item.description.split('\\\\n');


        return (
            <>
    
                <div class="col">
                        <div class="card h-60">
                            <div class="card-header bg-transparent border-0">
                            
                            <div class="card">
                                <img src={`https://api.myidealtd.co.uk/uploads/${item.image}`} class="img-fluid d-block w-100"/>
                            </div>
                                <h2 class="card-title text-center mt-5 ">{item.name}</h2>
                                <h1 class="card-title text-center mt-5 display-7"><strong>£{item.price}</strong></h1>
                 
                            </div>
                            <div class="card-body border-0">
                                <button className="btn_"onClick={()=>addtocart(item)}> 
                                        Add To Cart
                                </button>{'  '}   
				                    </div>
                            <div class="card-footer">
                                <br/>

                                <Accordion>
                                          <Accordion.Item eventKey="0">
                                                    <Accordion.Header>What’s included</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div id="example-collapse-text_0">
                                                            <Card style={{ width: '18rem' }}>
                                                                    <ListGroup variant="flush">
                                                                            <ListGroup.Item>
                                                                                      
                                                                                        {lines.map((line, index) => (
                                                                                              <React.Fragment key={index}>
                                                                                                <p><IoIosCheckboxOutline size='1.5rem' color= 'black'/> {line}</p> 
                                                                                                <br />
                                                                                              </React.Fragment>
                                                                                            ))
                                                                                        }   
                                                                                  
                                                                              </ListGroup.Item>
                                                                    </ListGroup>
                                                            </Card>
                                                        </div>
                                                    </Accordion.Body>
                                          </Accordion.Item>
                                </Accordion >
                            </div>

 

                        </div>
                </div>
        
            </>
         
        )
    }) : ""
}
     </div>

     
</div>
  )
}

export default Pricing


