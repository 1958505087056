


import React,{useEffect}  from 'react';
import "../App.css";
import home from        "../assets/img/slide/home.svg";
import runtime from     "../assets/img/slide/runtime.svg";
import RSupport from    "../assets/img/slide/RSupport.jpg";
import Heading from         '../components/Heading.js';
import Service from         '../components/Service.js';




function Home({emptycart}) {


 useEffect(()=> {
 


 },[]);


  return (
          <div class="container">

         
              <h1 class="display-1 font-weight-bold text-center mt-5"><strong>IT Support for Home User</strong></h1>
                  <div class="container">
                       <Heading/>
                    <div class="row">
                        <div class="col">
                            <div class="card h-70">
                                <div class="card-header bg-transparent border-0">
                                    <h4 class="card-title">Same-Day Service </h4>
                                    <p>Your trusted partner for Home IT Support. We specialise in providing personalised solutions for all your technology needs. From troubleshooting and maintenance to setting up secure networks, our experts are here to ensure your home devices run smoothly. Experience reliable Remote IT support that fits seamlessly into your lifestyle. Connect with myidealtd  IT Support Team for a seamless and stress-free technology experience at home.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>

                  <div class="container bg-light">
                      <div class="row">
                         <div class="col">
                            <img src={RSupport } class="img-fluid  w-50" alt="" />
                            <h7 class="p-1 text-end">Remote Support</h7>
                         </div>
                         <div class="col">
                            <img src={runtime } class="img-fluid  w-50" alt="" />  
                            <h7 class="p-1 text-end">30 min response </h7>      
                         </div>
                         <div class="col">
                             <img src={home } class="img-fluid  w-50" alt="" />
                             <h7 class="p-1 text-end">Home IT Service</h7>
                         </div>
                     </div>
                  </div>



                  <Service/>
   


              <h1 class="text-center mt-2"> Service in just 3 steps</h1>
              <hr/>
              <div class="container bg-light text-center">
                  <div class="row">
                      <div class="col-sm mt-5 text-right">
                        <div class="circle-singleline">1</div>
                          <div class="card h-55 border-0">
                              <div class="card-header bg-transparent border-0">
                                  <h3 class="card-title">Book your Service</h3>

                              </div>
                              <div class="card-body">
                                  <p class="card-text">Just call us or complete the 'Get in Touch' form, we will arrange either remote support or a site visit.</p>
                              </div>
                          </div>
                      </div>

                      <div class="col-sm mt-5">
                         <div class="circle-singleline">2
                         </div>
                          <div class="card h-55 border-0">
                              <div class="card-header bg-transparent border-0">
                                  <h3 class="card-title">Remote/Onsite Visit </h3>
                              </div>
                              <div class="card-body">
                                  <p class="card-text">30 min response (subject to availability)</p>
                              </div>
                          </div>
                      </div>

                      <div class="col mt-5">
                        <div class="circle-singleline">3</div>
                          <div class="card h-70 border-0">
                              <div class="card-header bg-transparent border-0">
                                  <h3 class="card-title"> Job Done</h3>
                              </div>
                              <div class="card-body">
                                  <p class="card-text">After completing work, we will explain the issue to you and provide possible solutions for future</p>
                              </div>
                          </div>
                      </div>
                  </div>


              </div>
      

           

              
         </div>
            
   

 

  )
}

export default Home
