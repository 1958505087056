
/*

Line no 102 -to 104 

     <div class="card">
        <img src={`https://api.myidealtd.co.uk/uploads/${item.image}`} class="img-fluid d-block w-100"/>
     </div>

For Production use -->  api.myidealtd.co.uk
For Testing use    -->  127.0.0.1
*/





import React,{useState,useEffect} from 'react';

import {Link,useParams,useNavigate} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import PayButton from '../components/PayButton'

import "../style/cart.css";


function Cart(props) {
    


const {cart,setCart,item,item_remove,inc_qty,dec_qty,qty} = props;

const [price, setPrice] = useState(0);

const amount_in_total = ()=>{

    let ans =0;
    cart.map((item)=> (
         ans += item.quantity * item.price
    ))
    setPrice(ans);
}


const handleChange = (item, d) => {
    const ind = cart.indexOf(item);
    cart[ind].quantity += d;

    if (cart[ind].quantity === 0) cart[ind].quantity = 1;
    setCart([...cart]);
  };




useEffect(()=>{



    amount_in_total();
})

return (

<div className="container mt-3">
            <div class="d-flex flex-column min-vh-5 justify-content-center align-items-center">
                <h1>Shopping Cart</h1>
               
            </div>
                
                  
            <div className="container-fluid mt-5">
                    <div className="row">
                        <div className="col">
                            Product
                        </div>
                        <div className="col">
                            Price
                        </div>
                       
                        <div className="col">
                            Total
                        </div>
                        <div className="col">
                            Action
                        </div>
                        
                    </div>
            </div>



   


       <div class="container mt-3"> 
            {cart.map((item) => (   
                <div className="row">
                    <div class="col">
                       {item.name}

                            <div class="card">
                                <img src={`https://api.myidealtd.co.uk/uploads/${item.image}`} class="img-fluid d-block w-100"/>
                            </div>

           
                    </div>
                    <div className="col">
                        £{item.price}
                    </div>
                   
                    <div className="col">
                        £{item.quantity * item.price}
                    </div>
                    
                    <div className="col">
                        <button class="_btn"  onClick={(e)=>item_remove(item._id)}>Remove</button>
                    </div>

                </div>   

            ))}

        </div>

      <hr/>

        <div className="total p-2"> 
                    <span><strong>Total Price of your Cart</strong></span>
                    <span><strong>   £{price}</strong></span>
        </div>
        
        <div class="container">
            <div class="py-5 text-center">
            <PayButton cart={cart}/>

            
            </div>    
        </div>


</div>
        
  )
}

export default Cart;
