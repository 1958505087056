/*

 <div class="collapse navbar-collapse" id="navbarToggler">
     <button class="btn  my-2 my-sm-0 ms-auto" type="submit">
            <a class="btn btn-lg btn-primary ms-auto " href="https://support.myidealtd.co.uk/getintouch">Get In Touch
            </a>
     </button>
 </div>

*/

import React from 'react'
import {  FiPhoneCall  } from "react-icons/fi";

function Navbarcontact() {
  return (
    <div class="container">
      <nav class="navbar navbar-expand-sm bg-light">

          <p class="p-2 fs-4">Home Tech Support: Fast Solution </p>

          <button class="btn_nav btn-lg btn-info"> 
              <p class="mt-3"> 
              Call Us 03302235529<br/>Mon-Sat: 8am - 6pm
              </p>
          </button> 

        <button class="navbar-toggler ms-auto" 
                type="button" 
                data-bs-toggle="collapse" 
                data-bs-target="#navbarToggler" 
                aria-controls="navbarToggler" 
                aria-expanded="false" 
                aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
        </button>



          <div class="collapse navbar-collapse" id="navbarToggler">
              <ul class="navbar-nav ms-auto">
                <li class="nav-item active ms-auto">
                   <p><a class="mt-3 _btn btn-primary text-sm" href="https://support.myidealtd.co.uk/getintouch">Get In Touch</a></p>
                </li>
              </ul>
          </div>




          
         
      </nav>
    </div>
    
  )
}

export default Navbarcontact
