
/*

Line no 22

  const url = "https://api.myidealtd.co.uk/api";

For Production use -->  api.myidealtd.co.uk
For Testing use    -->  127.0.0.1
*/



import axios from "axios";
import React from 'react'
import {Link,useNavigate} from 'react-router-dom';

function PayButton({cart}) {

    const url = "https://api.myidealtd.co.uk/api";

    const navigate = useNavigate();
    const handlecheckout = () => {
          axios
          .post(`${url}/stripe/create-checkout-session`, {
            cart,
      
          })
          .then((response) => {
            if (response.data.url) {
              window.location.href = response.data.url;
            }
          })
          .catch((err) => console.log(err.message));
    };


  return (
    <div>
   
         {
          (cart == 0) 
          ?
          ( 
            navigate('/')
          )
          :
          ( <button class="_btn" onClick={()=> handlecheckout()}>Continue to Check Out </button>)   
         

         }
        

    
  
     
    </div>
  );
};

export default PayButton;

