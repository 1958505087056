import React from 'react'
import logo__ from "../assets/img/slide/logo__.png";
import { IoCartOutline } from "react-icons/io5";
import {Link} from 'react-router-dom';




function NavbarMain({setShow,cart,setCart,res,empty_cart}) {
  return (
    <div class ="container">
    <nav class="navbar navbar-expand-sm navbar-dark bg-dark p-1">
        <a class="navbar-brand" href="/">
          <img
  　　　       src= {logo__}
              width="50" height="50"
              class="d-inline-block align-middle p-1"
  　　        /> myidea
        </a>

        <button class="navbar-toggler ms-auto" 
                type="button" 
                data-bs-toggle="collapse" 
                data-bs-target="#navbarToggler" 
                aria-controls="navbarToggler" 
                aria-expanded="false" 
                aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarToggler">
              <ul class="navbar-nav">
                <li class="nav-item active ms-auto">
                <a class="nav-link ms-auto" href="/pricing">Book Online</a>
                </li>
              </ul>

              <ul class="navbar-nav mx-auto">
                <li class="nav-item active mx-auto">
                <p class="p-2 fs-4 text-white"> Home Tech Assistance</p>
                </li>
              </ul>
             
              <ul class="navbar-nav ms-auto">
                <li class="nav-item active ms-auto">
                <a class="nav-link ms-auto" href="/cart"> <IoCartOutline  size='1.5rem' color= 'white'/> {cart.length}</a>
                </li>
              </ul>
        </div>
        <div class="text-white">
                   
                 
        </div>  


    </nav>
  </div>
  )
}

export default NavbarMain
