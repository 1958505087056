



import React, { useEffect, useState } from 'react'
import axios from "axios"
import Card from 'react-bootstrap/Card';
import {Link,useParams,useNavigate} from 'react-router-dom';
import moment from "moment"

function ProductList() {

  const navigate = useNavigate();
  const {id} = useParams(); 

    const [data, setData] = useState([]);

        const fetchProductList = async ()=> {

      const res = await axios.get('https://api.myidealtd.co.uk/api/products/');
       setData(res.data.Products)
 
      
      }
      
      // Delete Product
      const DltPro = async (id) => {
        const res = await axios.delete(`https://api.myidealtd.co.uk/delete/${id}`, {
            headers: {
                "Content-Type": "application/json"
            }
        });

        if (res.data.status === 401 || !res.data) {
            console.log("errror")
        } else {
            console.log("Product delete");

        }
    }


    useEffect(()=>{
      fetchProductList();
    },[DltPro])


  return (
    <div class="container text-center">
        <div class="row">
              <div class="col">
              Products 
              </div>
              <div class="col">
    
              </div>
            <div class="col">
                <Link to="/AddProduct">
                    <button type="button" class="btn btn-dark mt-3" > 
                           Create Product
                    </button>
                </Link>
            </div>
        </div>

        <table class="table">
            <thead>
                <tr>
                <th scope="col">ID</th>
                <th scope="col">NAME</th>
                <th scope="col">IMAGE</th>

                <th scope="col">DESCRIPTION</th>
                <th scope="col">PRICE</th>
                <th scope="col">ACTION</th>
 
                
                </tr>
            </thead>
            <tbody>
                {data.length > 0 ? data.map((item,i)=> (


                <tr key = {i}>

      
                    <td>{item._id}</td>
                    <td>{item.name}</td>
                    <td>
                        

                    <div class="card">
                          <img src={`https://api.myidealtd.co.uk/uploads/${item.image}`} class="img-fluid d-block w-100"/>
                    </div>
                                    
                      

                    </td>

                    <td>{item.description}</td>
                    <td>{item.price}</td>

                    <td>
                        
                        <Link to={`/${item._id}`}>
                              <button class="btn btn-secondary"> 
                                  Edit
                              </button>{'  '}
                        </Link>
                
                        <button 
                              variant="danger" 
                              className='btn btn-danger ' 
                              onClick ={()=>DltPro(item._id)}>
                              Delete
                        </button>

                    </td>
      
                </tr>
)):""}
            </tbody>
        </table>

    </div>
  )
}

export default ProductList
