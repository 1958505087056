import React from 'react'
import heading from "../assets/img/slide/heading.jpg";


function Heading() {
  return (
    <div>
         <div class="container">
            
         <img src={heading} 
                         class=" img-fluid d-block w-100" width="500" height="500"
               
                    /> 
        
        <p><a class="mt-3 _btn btn-lg btn-primary text-sm" href="/pricing">Book a Service</a></p>    
         </div>
    </div>
  )
}

export default Heading
