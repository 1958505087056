import React from 'react'
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div class ="container text-center mt-5">
      <h1 >Page Not Found </h1>
      <Link to="/"><h2 class="mt-5">Go Home</h2></Link>
    </div>
  )
}

export default NotFound
