import React from 'react'

import hnetworking from "../assets/img/slide/hnetworking.jpg";
import chcheck from     "../assets/img/slide/chcheck.jpg";
import vremoval from    "../assets/img/slide/vremoval.jpg";
import mdsupport from   "../assets/img/slide/mdsupport.jpg";

function Service() {
  return (

                <div class="container bg-light" id="services">
                  <h1 class="mt-5 text-center ">Our Services</h1>
                  <div class="row">
                      <div class="col-sm">
                          <div class="card" id="chcheck">
                          <img src={chcheck} class="img-fluid d-block w-100"/>
                              <div class="card-header bg-transparent border-0">
                                  <h4 class="card-title">Computer Health Check</h4>
                              </div>
                              <div class="card-body">
                   
                              <p class="mb-0">
                                 A computer health check involves assessing system performance, 
                                 scanning for malware, and ensuring software and hardware components 
                                 are optimized, contributing to overall reliability and efficiency. 
                                 Regular maintenance helps prevent issues, promoting a seamless computing experience.
                              </p>

                             <br/>
                             <br/>
                             <br/>
                             
                              </div>
                              <div  class="card-footer">
                                  <a href='/pricing'>
                                    <button type="button" class="_btn _btn-danger mt-2">Book Now </button>
                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="col">
                          <div class="card" id="vremoval">
                             <img src={vremoval } class="img-fluid d-block w-100" />
                              <div class="card-header bg-transparent border-0">
                                  <h4 class="card-title">Home Computer Virus Removal </h4>
                              </div>
                              <div class="card-body">
                           
                                 <p class="mb-0">
                                   Virus removal is the process of using antivirus software
                                   to scan,detect, and eliminate malicious code from a computer, 
                                   safeguarding against potential damage and ensuring a secure 
                                   computing environment. Timely removal helps restore system 
                                   health and prevent further threats.
                                 </p>   
                              </div>
                              <br/>
                              <br/>
                              <br/>
                              <div class="card-footer">
                      
                                 <a href= '/pricing'>
                                   <button type="button" class="_btn _btn-danger mt-2 "> Book Now </button>
                                 </a>
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-sm">
                                <div class="card" id="hnetworking">
                                <img src={hnetworking} class="img-fluid d-block w-100" />
                                    <div class="card-header bg-transparent border-0">
                                        <h4 class="card-title">Network Performance Check</h4>
                                    </div>
                                    <div class="card-body">
                                  
                                    <p class="mb-0">
                                      Home network performance tests assess the speed and 
                                      reliability of data transfer in a household, gauging 
                                      factors like Wi-Fi strength and device connectivity 
                                      to optimize internet speed and ensure a smooth online experience. 
                                      Regular testing helps identify and address issues for an efficient 
                                      and reliable home network.   </p>
                                    </div>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <div class="card-footer">
                                        <a href='/pricing'>
                                          <button type="button" class="_btn _btn-danger mt-2"> Book Now </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                
                            <div class="col">
                                <div class="card" id="mdsupport">
                                <img src={mdsupport} class="img-fluid d-block w-100" />
                                    <div class="card-header bg-transparent border-0">
                                        <h4 class="card-title">Mobile Device Support Service</h4>
                                    </div>
                                    <div class="card-body">
                
                                    <p class="mb-0">
                                       Home mobile device support provides assistance and troubleshooting 
                                       for smartphones and tablets, addressing hardware or software issues 
                                       and ensuring a seamless user experience within a household. 
                                       It includes guidance on configurations, connectivity, and resolving 
                                       technical challenges to optimize mobile device performance.
                                    </p>
                                    </div>
                                    <br/>
                                    <br/>
               
                                    <div class="card-footer">
                                
                                    <a href='/pricing'>
                                      <button type="button" class="_btn _btn-danger mt-2 ">Book Now</button>
                                  </a>
                                    </div>
                                </div>
                            </div>
                           
                            
                          </div>
                      </div>
              </div>

  )
}

export default Service
